import { registerApplication, start } from "single-spa";
import { VersionService } from './services/version.service';
import { environment } from '../environments/environment';

registerApplication({
  name: "@elcp/header",
  app: () => System.import("@elcp/header"),
  activeWhen: ["/"],
});

registerApplication({
  name: "@elcp/dashboard",
  app: () => System.import("@elcp/dashboard"),
  activeWhen: (location) =>
    !location.href.includes("/maintenance") &&
    !location.href.includes("/settings/service-environment") &&
    !location.href.includes("/dataanalytics/advancedReport") &&
    !location.href.includes("/settings/energy") &&
    !location.href.includes("/dataanalytics/advancedMonitoring") &&
    !location.href.includes("/dataanalytics/smartManufacturing") &&
    !location.href.includes("/dataanalytics/scoreCard") &&
    !location.href.includes("/dataanalytics/alarms")&&
    !location.href.includes("/dataanalytics/powerquality") &&
    !location.href.includes("/assetpage/singleLineDiagram") &&
    !location.href.includes("/smarttracker"),
});

registerApplication({
  name: "@elcp/footer",
  app: () => System.import("@elcp/footer"),
  activeWhen: ["/"],
});

registerApplication({
  name: "@elcp/maintenance",
  app: () => System.import("@elcp/maintenance"),
  activeWhen: (location) => location.href.includes("/maintenance"),
});

registerApplication({
  name: "@elsp/service-environment",
  app: () => System.import("@elsp/service-environment"),
  activeWhen: (location) =>
    location.href.includes("/settings/service-environment"),
});

registerApplication({
  name: "@elsp/utility",
  app: () => System.import("@elsp/utility"),
  activeWhen: (location) => location.href.includes("/settings/energy"),
});

registerApplication({
  name: "@elsp/advanced-report",
  app: () => System.import("@elsp/advanced-report"),
  activeWhen: (location) =>
    location.href.includes("/dataanalytics/advancedReport"),
});

registerApplication({
  name: "@elsp/advanced-monitoring-targeting",
  app: () => System.import("@elsp/advanced-monitoring-targeting"),
  activeWhen: (location) =>
    location.href.includes("/dataanalytics/advancedMonitoring"),
});

registerApplication({
  name: "@elsp/energy-performances",
  app: () => System.import("@elsp/energy-performances"),
  activeWhen: (location) =>
    location.href.includes("/dataanalytics/smartManufacturing"),
});

registerApplication({
  name: "@elsp/scorecard",
  app: () => System.import("@elsp/scorecard"),
  activeWhen: (location) => location.href.includes("/dataanalytics/scoreCard"),
});

registerApplication({
  name: "@elsp/advanced-alarms",
  app: () => System.import("@elsp/advanced-alarms"),
  activeWhen: (location) => location.href.includes("/dataanalytics/alarms"),
});

registerApplication({
  name: "@elsp/powerquality",
  app: () => System.import("@elsp/powerquality"),
  activeWhen: (location) =>
    location.href.includes("/dataanalytics/powerquality"),
});

registerApplication({
  name: "@elsp/single-line-diagram",
  app: () => System.import("@elsp/single-line-diagram"),
  activeWhen: (location) =>
    location.href.includes("/assetpage/singleLineDiagram"),
});

registerApplication({
  name: "@elsp/smart-tracker",
  app: () => System.import("@elsp/smart-tracker"),
  activeWhen: (location) =>
    location.href.includes("/smarttracker"),
});

start({
  urlRerouteOnly: true,
});
export function getAccessToken() {
  return localStorage.getItem("accessToken");
}

window.addEventListener("single-spa:before-routing-event", (evt) => {
  const tokenvalue = getAccessToken();
  if (tokenvalue != null) {
    document.getElementById("loading").style.display = "block";
  }
});

window.addEventListener("single-spa:routing-event", (evt) => {
  document.getElementById("loading").style.display = "none";
});
window.addEventListener('single-spa:routing-event', (evt:any) => {
  const tokenvalue = getAccessToken();
  if(evt.detail.newUrl!=evt.detail.oldUrl)
  {
    if (tokenvalue != null) {
      let key =localStorage.getItem('Key');
      if(key!=null)
      {
        VersionService.getInstance().getVersion().subscribe((response) => {
          let version= response;
          if(version!=null && version!="")
          {
            let buildVersion =localStorage.getItem('BuildVersion');
            if(version!=buildVersion && buildVersion!=null)
            {
              localStorage.setItem("BuildVersion", version.toString());
              var loadingElement = document.getElementById("modalDialog");
              if (typeof loadingElement !== "undefined" && loadingElement != null) {
                loadingElement.style.display = "block";
              }          
            }
            else if(buildVersion==null)
            {
              localStorage.setItem("BuildVersion", version.toString());
            }
        }
        })
      }
    }
  }
});
document.getElementById("btnConfirm").addEventListener("click", function(){
  let  redirectURL= localStorage.getItem('redirectURL');
  localStorage.clear();
  caches.keys().then(list => list.map(key => caches.delete(key)));
  window.location.reload(true);
  const oauthLogOutUrl = `https://login.microsoftonline.com/${environment.tenantId}/oauth2/logout?post_logout_redirect_uri=${encodeURI(redirectURL)}`;
  window.location.href = oauthLogOutUrl;
});